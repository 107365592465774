#initialBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100vh;
    padding-bottom: 20vmax;
    overflow-x: hidden;
}

#landingBG {
    position: fixed;
    top:-10vmin;
    left:-2vmax;
    z-index: -1;
    width: 100vw;
    height: 150vh;
}

#landingBG img{
    width: 100vw;
    height: 150vh;
}

#myName{
    margin: 1vmax;
    text-align: center;
    font-family: 'Source Sans Pro', 'Source Sans 3', sans-serif;
    color: white;
    font-weight: 900;
    font-style: italic;
    font-size: 5vmax;
    filter: drop-shadow(1px 3px 2px black);
}

#initSep{
    width: 30vmax;
    border: 0.15vmax solid black;
    background-color: black;
}

#descr{
    margin: 2.25vmax;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 1.25vmax;
    filter: drop-shadow(1px 2px 1px black);
}