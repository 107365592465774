#preFooter{
    background-color: #252525;
}

.preFooterIcon{
    margin: 0.4vw 4.8vw;
    padding: 0;
    width: 3.2vmax;
    height: auto;
    border-radius: 0.4vw;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}

.preFooterIcon:hover{
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.75));
}

.preFooterP{
    margin:0;
    text-align: center;
    color: white;
    font-size: 1.2rem;
}

#SMLinks{
    display: flex;
    justify-content: center;
    margin: 0;
}