.projectDiv{
    display: inline-block;
    margin: 3.2vw 0 3.2vw 0;
    padding: 0;
    border-radius: 1.8vmax;
    width: 28.8vw;
    height: 40vw;
    filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5));
    overflow: clip;
    border: 0;
    z-index: 1;
    tab-index: 0;
}

@keyframes bgBlurIN {
    0%{
        filter: blur(0);
    }
    100%{
        filter: blur(8px);
    }
}

@keyframes bgBlurOUT {
    0%{
        filter: blur(8px);
    }
    100%{
        filter: blur(0);
    }
}

.projectDiv .bgHolder{
    margin:0; padding:0;
    width: 100%; height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    animation: bgBlurOUT 250ms ease-out;
}

.projectDiv:hover .bgHolder {
    animation: bgBlurIN 250ms ease-in;
    filter: blur(8px);
}

@keyframes heightUP {
    0%{
        height: 4.2vmax;
    }
    100%{
        height: calc(100% - 2.4vw);
    }
}

@keyframes heightDOWN {
    0%{
        height: calc(100% - 2.4vw);
    }
    100% {
        height: 4.2vmax;
    }
}

 /* FIXME: The problem with animation comes from here (NOT ONLY IPHONE, SAFARI OVERALL!!!):
             1. iPhone animations of projects
             2. The x-scroll that appears every time I reload on iPhone
             3. The rotated screen on iPhone for the projects' view*/
.projectContentHolder{
    animation: heightDOWN 350ms ease-out;
    margin: 0;
    padding: 1.2vmax;
    display: inline;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2.4vmax);
    height: 4.2vmax;
    background-color: rgba(37, 37, 37, 0.9);
    overflow:hidden;
    color: white;
}

.projectDiv:hover .projectContentHolder{
    animation: heightUP 350ms ease-in;
    height: calc(100% - 2.4vmax);
}

.projectContentHolder h1{
    font-size: 1.8vmax;
    text-align: center;
    margin-bottom: 2.4vmax;
}

.projectDescription{
    font-size: 1.2vmax;
    text-align: justify;
    margin: 0 1.2vmax 1.2vmax 1.2vmax;
}

.usedTechs{
    margin: 0; padding: 0;
    font-size: 1vmax;
    font-style: italic;
    text-align: center;
}

@media screen and (max-aspect-ratio: 2/5) {
    .projectDiv{
        width: 72vw;
        height: 100vw;
    }
    .projectDescription{
        font-size: 0.9rem;
    }
    .usedTechs{
        font-size: 0.7rem;
    }
}

@media screen and (min-aspect-ratio: 2/5) and (max-aspect-ratio: 4/5){
    .projectDiv{
        width: 72vw;
        height: 100vw;
    }
    .projectDescription{
        font-size: 1.3rem;
    }
    .usedTechs{
        font-size: 1.2rem;
    }
}

@media screen and (min-aspect-ratio: 4/5) and (max-aspect-ratio: 12/10){
    .projectDiv{
        width: 48vw;
        height: 67vw;
    }
    .projectDescription{
        font-size: 1.4rem;
    }
    .usedTechs{
        font-size: 1.2rem;
    }
}