#Programming{
    background-color: #1E1E1E;
    overflow: auto;
    padding-bottom: 9.6vmax;
}

#Programming h2{
    margin:9.6vmax 0 3.2vmax 0;
    position: relative;
    color: white;
    text-align: center;
    font-size: 2.25vmax;
    font-weight: 700;
    z-index: 1;
}

#Projects{
    padding: 0 9.6vw 0 9.6vw;
    display: grid;
    margin: 0;
    place-items: center;
    overflow: hidden;
}

@media screen and (max-aspect-ratio: 12/10) {
    #Projects{
        grid-template-columns: repeat(1, auto);
    }
}

@media screen and (min-aspect-ratio: 12/10){
    #Projects{
        grid-template-columns: repeat(2, auto);
    }
}