#About{
    background-color: #1E1E1E;
    overflow: auto;
}

#picAndBriefDescription{
    display: flex;
    justify-content: center;
    margin: 16vmax 0 16vmax 0;
    height: 24vmax;
    background-image: url("../Pics/IcelandPic.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#picAndBriefDescription p{
    font-size: 1.2rem;
    margin: auto;
    text-align: center;
    width: 40vw;
    background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-device-width: 650px){
    #picAndBriefDescription{
        background-attachment: scroll;
    }
    #picAndBriefDescription p{
        width: 60vw;
        margin-right: 35vw;
    }
}


