#Creativity{
    padding: 9.6vmax;
    background-color: #252525;
    overflow: visible;
    z-index: 0;
}

#orangeDiv{
    position: relative;
    width:0;
    height:0;
    margin:0;
    padding:0;
}
#orangeBG{
    position: absolute;
    left: -50vw;
    top: -30vh;
    width: 80vw;
    height: auto;
}

.sectionTitle{
    margin:0;
    color: white;
    text-align: right;
    font-size: 2rem;
    font-weight: 700;
}

.sectionText{
    margin: 3.2vmax 0 0 12.8vmax;
    color: white;
    font-size: 1.25rem;
    text-align: justify;
    filter: drop-shadow(0px 4px 2px black);
}

@media screen and (max-width: 650px){
    .sectionText{
        margin-left: 0;
    }
}

@media screen and (min-aspect-ratio: 9/10){
    @keyframes img1hovered {
        0%{
            transform: none;
        }
        100%{
            transform: translate(-8vmax, -1vmax);
        }
    }

    @keyframes img1unhovered {
        100%{
            transform: none;
        }
        0%{
            transform: translate(-8vmax, -1vmax);
        }
    }

    @keyframes img2hovered {
        0%{
            rotate: -8deg;
        }
        100%{
            rotate: 0deg;
        }
    }

    @keyframes img2unhovered {
        100%{
            rotate: -8deg;
        }
        0%{
            rotate: 0deg;
        }
    }

    @keyframes img3hovered {
        0%{
            rotate: 0deg;
            transform: translateX(+6vmax);
        }
        100%{
            rotate: 16deg;
            transform: translate(+14vmax, -3vmax);
        }
    }

    @keyframes img3unhovered {
        100%{
            rotate: 0deg;
            transform: translateX(+6vmax);
        }
        0%{
            rotate: 16deg;
            transform: translate(+14vmax, -3vmax);
        }
    }

    #imgGallery{
        padding-top: 4.8vmax;
        position:relative;
        left: 38.4vw;
        width: 18.2vmax;
        height: 16.8vmax;
        z-index: 1;
        filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.75));
    }

    #CreativityPic1{
        animation: img1unhovered 350ms ease-out;
        position: absolute;
        height: 16.8vmax;
        width: auto;
        rotate: -16deg;
    }

    #CreativityPic2{
        animation: img2unhovered 350ms ease-out;
        position: absolute;
        height: 16.8vmax;
        width: auto;
        rotate: -8deg;
        transform: translateX(+3vmax);
    }

    #CreativityPic3{
        animation: img3unhovered 350ms ease-out;
        position: absolute;
        height: 16.8vmax;
        width: auto;
        transform: translateX(+6vmax);
    }

    #imgGallery:hover #CreativityPic1{
        animation: img1hovered 350ms ease-out;
        transform: translate(-8vmax, -1vmax);

    }

    #imgGallery:hover #CreativityPic2{
        animation: img2hovered 350ms ease-out;
        rotate: 0deg;
    }

    #imgGallery:hover #CreativityPic3{
        animation: img3hovered 350ms ease-out;
        transform: translate(+14vmax, -3vmax);
        rotate: 16deg;
    }
}

@media screen and (max-aspect-ratio: 9/10){
    #imgGallery{
        display: flex;
        position: center;
        justify-content: center;
        padding-top: 4.8vh;
        width: 100%;
        height: 36vw;
        z-index: 1;
        filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.75));
    }

    #CreativityPic1{
        transform: translateX(-22.4vw);
        position: absolute;
        height: 33.6vw;
        width: auto;
        rotate: -5deg;
    }

    #CreativityPic2{
        position: absolute;
        height: 33.6vw;
        width: auto;
    }

    #CreativityPic3{
        position: absolute;
        height: 33.6vw;
        width: auto;
        transform: translateX(+22.4vw);
        rotate: 5deg;
    }
}