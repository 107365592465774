#Contact{
    padding-bottom: 10vw;
    background-color: #1E1E1E;
    overflow: visible;
    z-index: 0;
}

#orangeDiv2{
    position: relative;
    width:0;
    height:0;
    margin:0;
    padding:0;
}

#orangeBG2{
    position: absolute;
    left: 50vw;
    top: -150vw;
    width: 100vw;
    rotate: 40deg;
    height: 200vw;
}

#ContactForm{
    margin: 0 auto 0 auto;
    padding: 4.8vw;
    width: 41.6vw;
    background-color: #252525;
    border-radius: 1.8vw;
    filter: drop-shadow(4px 4px 40px rgba(0, 0, 0, 0.5));
}

#Contact h2{
    margin:0 0 4.8vw 0;
    color: white;
    text-align: center;
    font-size: 2.4vmax;
    font-weight: 700;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#Contact input:not([type="submit"]){
    outline: none;
    display: block;
    width: calc(100% - 4.8vw);
    margin: 2.4vw auto 0 auto;
    padding: 0.8vw 1.6vw;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
    font-family: "Source Sans Pro", "Source Sans 3", sans-serif;
    font-size: 1.6vmax;
    color: white;
}

/*This removes any default browser autofilled field styles*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
}

#Contact input:not([type="submit"]):focus{
    border: none;
    border-bottom: 2px solid #F9E79F;
}

#Contact input:not([type="submit"]):focus::placeholder{
    color: #F9E79F;
}

#contactEmailContent{
    outline: none;
    resize: none;
    display: block;
    margin: 3.2vw 0 0 0;
    padding: 2.4vw;
    background-color: transparent;
    border: 2px solid gray;
    border-radius: 1.6vw;
    width: calc(100% - (4.8vw + 4px));
    height: 14.4vw;
    font-family: "Source Sans Pro", "Source Sans 3", sans-serif;
    font-size: 1.6vmax;
    color: white;
    scrollbar-width: thin;
}

#contactEmailContent::-webkit-scrollbar {
    width: 0.8vw;
}

#contactEmailContent::-webkit-scrollbar-thumb{
    background: rgba(255, 255, 255, 0.6);
    border-radius: 2.4vw;
}

#contactEmailContent::-webkit-scrollbar-thumb:hover{
    background: rgba(255, 255, 255, 0.4);
}

#contactEmailContent::-webkit-scrollbar-thumb:active{
    background: rgba(255, 255, 255, 0.2);
}

#contactEmailContent::-webkit-scrollbar-track{
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2.4vw;
}

#contactEmailContent::-webkit-scrollbar-button{
    background: transparent;
}

#contactEmailContent:focus{
    border: 2px solid #F9E79F;
}

#contactEmailContent:focus::placeholder{
    color: #F9E79F;
}

#submitContactForm{
    display: block;
    margin: 3.2vw auto 0 auto;
    width: calc(100% - 6.4vw);
    height: 3.2vw;
    border: none;
    background-color: #F9E79F;
    border-radius: 1.6vw;
    font-family: "Source Sans Pro", "Source Sans 3", sans-serif;
    font-size: 1.6vw;
    color: black;
    font-weight: 700;
}

#submitContactForm:hover, #submitContactForm:active{
    background-color: #e1c465;
    cursor: pointer;
}

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes fade-out {
    100%{
        opacity: 0;
    }
    0%{
        opacity: 1;
    }
}

.contactAlert{
    position: fixed;
    top:1.6vmax;
    left:20vw;
    width:47.2vw;
    margin:0;
    padding: 3.2vw 6.4vw;

    background-color: #252525;
    border-radius: 1.6vw;
    border: none;
    color: #e1c465;

    text-align: center;
    font-size: 1.6vw;

    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.75));
    z-index: 1000;
}

.contactAlert[open]{
    animation: fade-in 350ms forwards;
}

.contactAlert[closing]{
    animation: fade-out 350ms forwards;
}

@media screen and (max-aspect-ratio: 1/1){
    #ContactForm{
        width: 70vw;
    }

    #Contact input:not([type="submit"]){
        font-size: 1.6rem;
    }

    #Contact h2{
        font-size: 2rem;
    }

    #contactEmailContent{
        font-size: 1.6rem;
        height: 30vh;
    }

    #submitContactForm{
        font-size: 1.6rem;
        height: 4vh;
        border-radius: 2vh;
    }

    .contactAlert{
        top: 10vh;
        font-size: 1.5rem;
    }
}